import React, { useState } from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { standardWrapper, colors, headlineOne, buttonOne } from "../Utilities"

import InputText from "./formParts/InputText"
import InputTextArea from "./formParts/InputTextArea"
import RadioInput from "./formParts/RadioInput"

import { submitTheForm } from "./formParts/FormUtilities/formFunctions"
import ErrorDisplay from "./formParts/FormUtilities/ErrorDisplay"
import SubmitDisplay from "./formParts/FormUtilities/SubmitDisplay"
import SuccessDisplay from "./formParts/FormUtilities/SuccessDisplay"

const FormStyled = styled.section`
  position: relative;
  margin-top: 15rem;
  padding: 20rem 0 15rem;
  background-color: rgba(157, 201, 228, 0.15);

  .formImage {
    position: absolute;
    top: -13.5rem;
    right: 0;
    left: 0;
    width: 27.5rem;
    height: 27.5rem;
    margin: 0 auto;
    border-radius: 50%;
    overflow: hidden;
    border: 2rem solid ${colors.white};
  }
  .wrapper {
    ${standardWrapper};
  }

  .formTitle {
    text-align: center;
    h2 {
      ${headlineOne};
      color: ${colors.colorPrimary};

      span {
        display: block;
      }
    }
  }

  .formWrapper {
    width: 100%;
  }

  .formContainer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .formButton {
    button {
      ${buttonOne};
      min-width: 100%;
      padding-left: 0;
      background: transparent;
    }
  }
`

const Form = ({ image, titleTop, titleBot }) => {
  const [formData, updateFormData] = useState({
    fullName: "",
    phone: "",
    address: "",
    email: "",
    hear: "",
    whoReferred: "",
    details: "",
  })

  const [formStatus, updateFormStatus] = useState({
    submitting: false,
    errorWarnDisplay: false,
    success: false,
    errors: [],
  })

  const handleOnChange = e => {
    updateFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleOnSubmit = (e, formId, formData, updateFormStatus) => {
    updateFormStatus({
      ...formStatus,
      submitting: true,
    })

    submitTheForm(e, formId, formData, updateFormStatus)
  }

  return (
    <FormStyled>
      <div className="formImage">
        <Img
          fluid={image.localFile.childImageSharp.fluid}
          alt={image.alt_text}
        />
      </div>
      <div className="wrapper">
        <div className="formTitle">
          <h2>
            <span>{titleTop}</span>
            <span>{titleBot}</span>
          </h2>
        </div>
        <div className="formWrapper">
          <form
            onSubmit={e => {
              handleOnSubmit(e, 1788, formData, updateFormStatus)
            }}
          >
            <div className="formContainer">
              <InputText
                name="fullName"
                type="text"
                placeholder="Full Name"
                label="Name"
                value={formData.fullName}
                onChange={handleOnChange}
                errors={formStatus.errors ? formStatus.errors : []}
                required={true}
                width="half"
              />
              <InputText
                name="phone"
                type="text"
                placeholder="Phone Number"
                label="Phone"
                value={formData.phone}
                onChange={handleOnChange}
                errors={formStatus.errors ? formStatus.errors : []}
                required={true}
                width="half"
              />
              <InputText
                name="address"
                type="text"
                placeholder="Address"
                label="Address"
                value={formData.address}
                onChange={handleOnChange}
                errors={formStatus.errors ? formStatus.errors : []}
                required={false}
                width="full"
              />
              <InputText
                name="email"
                type="email"
                placeholder="Email Address"
                label="Email"
                value={formData.email}
                onChange={handleOnChange}
                errors={formStatus.errors ? formStatus.errors : []}
                required={true}
                width="full"
              />
              <RadioInput
                label="How did you hear about us?"
                name="hear"
                options={[
                  {
                    id: "backForMore",
                    label: "I'm back for more; we've already worked together",
                  },
                  {
                    id: "someoneIKnow",
                    label: "Someone I know has hired you before",
                  },
                  {
                    id: "workWithSomeone",
                    label: "We know someone who works with you",
                  },
                  { id: "social", label: "Social Media" },
                  { id: "sign", label: "Lawn or truck sign" },
                  { id: "google", label: "Google Search" },
                  { id: "print", label: "Print Promotion" },
                ]}
                handleOnRadioChange={handleOnChange}
                value={formData.hear}
                required={true}
              />
              <InputText
                name="whoReferred"
                type="text"
                placeholder="Who referred you?"
                label="Who referred you?"
                value={formData.whoReferred}
                onChange={handleOnChange}
                errors={formStatus.errors ? formStatus.errors : []}
                required={false}
                width="full"
              />
              <InputTextArea
                name="details"
                placeholder="Project Details"
                label="Project details: add a few short details about what you’re looking for."
                value={formData.details}
                onChange={handleOnChange}
                errors={formStatus.errors ? formStatus.errors : []}
                required={true}
                rows={5}
              />
              <div className="formButton">
                <button>Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {formStatus.submitting && <SubmitDisplay />}
      {formStatus.errorWarnDisplay && (
        <ErrorDisplay formStatus={formStatus} stateSetter={updateFormStatus} />
      )}
      {formStatus.success && (
        <SuccessDisplay
          updateFormData={updateFormData}
          updateFormStatus={updateFormStatus}
        />
      )}
    </FormStyled>
  )
}

export default Form
