import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

import LogoDarkBlue from "../elements/LogoDarkBlue"
import {
  standardWrapper,
  colors,
  headlineOne,
  headlineThree,
  fontSizer,
} from "../Utilities"

const getData = graphql`
  query connectOptions {
    connectData: wordpressAcfOptions {
      options {
        ikag_site_phone
        ikag_site_email
      }
    }
  }
`

const ConnectSection = styled.section`
  .wrapper {
    ${standardWrapper};
    align-items: center;
  }

  .connectLogo {
    width: 14rem;
    margin-bottom: 3rem;

    @media (min-width: 768px) {
      margin-right: 4rem;
      margin-bottom: 0;
    }
  }

  .connectLinks {
    text-align: center;

    @media (min-width: 768px) {
      text-align: left;
    }

    a {
      display: block;
    }

    a.phone {
      ${headlineOne};
      margin-bottom: 3rem;
      color: ${colors.colorPrimary};
      @media (min-width: 768px) {
        margin-bottom: 1rem;
      }
    }

    a.email {
      ${headlineThree};
      ${fontSizer(1.8, 2.4, 76.8, 160, 1.8)}
      color: ${colors.colorPrimary};
    }
  }
`

const Connect = () => {
  const data = useStaticQuery(getData)
  const {
    connectData: { options },
  } = data

  const phone = options.ikag_site_phone
  const email = options.ikag_site_email

  return (
    <ConnectSection>
      <div className="wrapper">
        <div className="connectLogo">
          <LogoDarkBlue />
        </div>
        <div className="connectLinks">
          <a className="phone" href={`tel:+1${phone}`}>
            {phone}
          </a>
          <a className="email" href={`mailto:${email}`}>
            {email}
          </a>
        </div>
      </div>
    </ConnectSection>
  )
}

export default Connect
