import React from "react"
import styled from "styled-components"
import { fontSizer, colors } from "../../Utilities"

const RadioInput = ({
  handleOnRadioChange,
  name,
  options,
  value,
  label,
  required,
}) => {
  return (
    <RadioInputStyled>
      <p className="label">
        {label} {required && <span> &#42;Required Field</span>}
      </p>
      {options.map(option => {
        return (
          <div className="checkbox-wrapper" key={option.id}>
            <input
              type="radio"
              id={option.id}
              name={name}
              value={option.id}
              checked={value === option.id}
              onChange={event => handleOnRadioChange(event)}
              required={required}
            />
            <label htmlFor={option.id}>
              <span>{option.label}</span>
            </label>
          </div>
        )
      })}
    </RadioInputStyled>
  )
}

const RadioInputStyled = styled.div`
  margin-top: 4rem;
  width: 100%;

  p {
    ${fontSizer(1.2, 1.4, 76.8, 150, 2)};
    position: absolute;
    top: 0.75rem;
    left: 0rem;
    color: red !important;
    z-index: 10;
  }

  p.label {
    position: relative;
    top: 0;
    left: 0rem;
    color: ${colors.colorPrimary} !important;

    span {
      color: ${colors.colorTertiary};
    }
  }

  .checkbox-wrapper {
    position: relative;
    margin-bottom: 1rem;
  }

  label {
    ${fontSizer(1.2, 1.4, 76.8, 150, 2)};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    padding-right: 5rem;
    cursor: pointer;

    &::before,
    &::after {
      pointer-events: none;
      content: " ";
    }

    span {
      width: 85%;
    }

    &::before {
      display: flex;
      width: 2rem;
      height: 2rem;
      border: solid 0.1rem ${colors.colorAlt};
      background: ${colors.white};
      margin-right: 1rem;
    }

    &::after {
      display: flex;
      position: absolute;
      top: 45%;
      left: 0.5rem;
      width: 1.2rem;
      height: 0.75rem;
      border-bottom: solid 0.4rem ${colors.colorAlt};
      border-left: solid 0.4rem ${colors.colorAlt};
      background: none;
      transform: rotate(-45deg) scale(0);
      transition: transform 0.3s ease;

      @media (min-width: 768px) {
        top: 0.5rem;
      }
    }

    &:hover {
      color: ${colors.colorAlt};
    }
  }

  input {
    position: absolute;
    top: 50%;
    left: 0;
    width: 2rem;
    height: 2rem;
    -webkit-appearance: none;
    cursor: pointer;
    transform: translateY(-50%);

    @media (min-width: 768px) {
      top: 0;
      left: 0;
      width: 2rem;
      height: 2rem;
      transform: translateY(0%);
    }

    &:checked + label:after {
      opacity: 1;
      transform: rotate(-45deg) scale(1);
    }
  }
`

export default RadioInput
